<script>
import { mapState } from 'vuex';
import Button from '@/components/Button';

const UNISWAP = process.env.VUE_APP_UNISWAP;
const PANCACKESWAP = process.env.VUE_APP_PANCACKESWAP;
const PANGOLINI = process.env.VUE_APP_PANGOLINI;

export default {
  name: 'ProductCard',
  components: { Button },
  props: {
    product: {
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    disableBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      milkPrice: (state) => state.Milk.price || 0,
      tokensPerYear: (state) => state.Shadow.tokensPerYear || 0,
      totalWeight: (state) => state.Shadow.totalWeight || 0,
      chainId: (state) => state.User.chainId,
    }),
    isBinance() {
      return this.chainId === 56 || this.chainId === 97 ? 'binance/' : '';
    },
    isApr() {
      return [1, 4, 56, 97].includes(this.chainId);
    },
    tradeUrl() {
      return this.$chain.getLinkByNetwork(
        `${UNISWAP}/#${this.product.tradeLink}`,
        `${PANCACKESWAP}/#/${this.product.tradeLink || 'swap'}`,
        '',
        `${PANGOLINI}${this.product.tradeLink}`,

      );
    },
    liqUrl() {
      return this.$chain.getLinkByNetwork(
        `${UNISWAP}/#${this.product.liquidityLink}`,
        `${PANCACKESWAP}/#${this.product.liquidityLink}`,
        '',
        `${PANGOLINI}${this.product.liquidityLink}`,
      );
    },
    cardOrder() {
      let order;
      switch (this.product.name) {
        case 'Banana':
          order = 1;
          break;
        case 'Cream soda':
          order = 2;
          break;
        case 'Chilli':
          order = 3;
          break;
        case 'Coconut':
          order = 4;
          break;
        case 'Avocado':
          order = 5;
          break;
        case 'Candy floss':
          order = 5;
          break;
        case 'Rainbow':
          order = 5;
          break;
        default:
          order = 0;
          break;
      }
      return {
        order,
      };
    },
    apy() {
      let bnApy = this.$BN(this.milkPrice)
        .times(this.tokensPerYear)
        .times(this.product.weight)
        .div(this.$BN(10).pow(2))
        .div(this.product.poolLockedPrice)
        .div(this.totalWeight);

      if (this.product.mul) {
        bnApy = bnApy.multipliedBy(this.product.mul);
      } else {
        bnApy = bnApy.multipliedBy(1000)
          // eslint-disable-next-line no-nested-ternary
          .multipliedBy(this.product.type === 'galaxy' || this.product.type === 'interstellar'
            ? this.product.hot ? 10 : 3
            : 1);
      }

      const amount = bnApy.comparedTo(1) < 1 ? bnApy.plus(10) : bnApy;
      return amount.multipliedBy(this.isBinance ? 2 : 1);
    },
  },
  methods: {
    getIconSrc() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`../assets/img/${this.isBinance}pools-icons/${this.product.icon}.svg`);
    },
  },
};
</script>
<template>
  <v-col md="4" sm="4" xs="12" :style="cardOrder">
    <div class="product-card main-block" :class="product.hot ? 'accent' : ''">
      <div class="accent-box" v-if="product.hot">
        <div></div>
      </div>
      <div class="product-card__markers">
        <img
          v-if="product.hot"
          class="product-card__hot"
          :src="require(`@/assets/img/${$chain.getName()}/hot.svg`)"
        />
        <div v-if="showLabel" class="product-card__uni">
          <img
            :src="chainId === 56 || chainId === 97
                ? require('@/assets/img/binance/ic-pancake.svg')
                : ($chain.getName() === 'avalanche' ? require('@/assets/img/pangolin.svg')
                : require('@/assets/img/ic-uniswap.svg'))
            "
          />
        </div>
      </div>
      <div class="product-card__logo">
        <img :alt="product.name" :src="getIconSrc()"/>
      </div>
      <div class="product-card__header">
        <span class="product-card__title">{{ product.name }}</span>
        <span class="product-card__name" v-html="product.symbol"></span>
      </div>
      <div class="product-card__param">
        <span v-if="isApr"> {{ $t('shadow.pool-apr') }} </span>
        <span v-else>{{ $t('shadow.pool-apr') }}</span>
        <span v-if="!product.apr">
          {{ isNaN(apy) ? 'Loading...' : `${$options.filters.amount(apy, 2)} %` }}
        </span>
        <span v-else>{{  `${product.apr}%` }}</span>
      </div>
      <div class="product-card__param">
        <span>{{ $t('shadow.your-token-balance') }}</span>
        <span>
          {{ product.balance ? product.balance : 0 | amount(5) }}
          {{ $t('shadow.lp') }}
        </span>
      </div>
      <div class="product-card__param">
        <span>{{ $t('shadow.total-locked') }}</span>
        <span>
          {{ product.totalLpSupply ? $contracts.fromWei(product.totalLpSupply) : 0 | amount(4) }}
          {{ $t('shadow.lp') }}
        </span>
      </div>
      <div class="product-card__btn">
        <Button
          :href="tradeUrl"
          target="_blank"
        >
          {{ $t('product.trade') }}
        </Button>

        <Button
          v-if="product.liquidityLink"
          :href="liqUrl"
          target="_blank"
        >
          {{ $t('product.add_liq') }}
        </Button>
      </div>
    </div>
  </v-col>
</template>

<style lang="scss">
.product-card {
  font-size: 1rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.8em 3.3em 2.3em;

  &.accent {
    box-shadow: none;
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
    margin: 0 auto;
    display: block;
    font-size: 1.3rem;
    padding: 3em 2.6em 2.3em;
  }

  &__markers {
    position: absolute;
    top: 2em;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0 3.3em;
    @media screen and (max-width: $breakpoint-sm) {
      justify-content: flex-end;
      top: 1.6em;
      padding: 0 2em;
    }
  }

  &__uni {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.5rem;
    height: 4.5em;
    padding: 0.5rem;
    border-radius: 5em;
    box-shadow: 1em 1em 1.8em rgba(222, 222, 222, 0.25), 0.2em 0.2em 1.8em rgba(212, 202, 205, 0.2),
    -1em -1em 1.4em #ffffff, 1em 1em 1.8em rgba(181, 155, 155, 0.25),
    inset 0.2em 0.2em 6px #ffffff, inset -0.1em -0.1em 0.2em rgba(181, 155, 155, 0.2);
    margin-left: auto;

    body.binance & {
      background: $b-main-bg;
      box-shadow: $b-pools-select-shadow;
    }

    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 1em;
      width: 3.4em;
      height: 3.4em;
    }
  }

  &__hot {
    height: 5em;
    width: auto;
    @media screen and (max-width: $breakpoint-sm) {
      left: auto;
      right: 0;
      height: 3em;
    }
  }

  &__logo {
    padding-top: 53%;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;

    @media screen and (max-width: $breakpoint-sm) {
      padding-top: 0;
      position: absolute;
      height: 7em;
      width: 10em;
      left: 0;
      top: 2.5em;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      max-width: 100%;
      @media screen and (max-width: $breakpoint-sm) {
        position: initial;
        display: block;
        max-width: none;
        transform: none;
        margin: 0 auto;
      }
    }
  }

  &__header {
    text-align: center;
    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 8em;
      text-align: left;
    }
  }

  &__title {
    font-size: 3em;
    line-height: 120%;
    margin-bottom: 0.4em;
    font-weight: bold;
    display: block;
    @media screen and (max-width: $breakpoint-sm) {
      font-size: 2em;
    }
  }

  &__name {
    display: block;
    font-size: 1.5em;
    font-weight: 500;
    color: $grey-font-color;
    height: 3em;

    br {
      display: none;
    }

    body.binance & {
      color: $b-secondatry-font-color;
    }

    @media screen and (max-width: $breakpoint-sm) {
      height: 3em;
      align-items: center;
      display: flex;
      margin-bottom: 1.5em;
    }
  }

  &__param {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.3em;
    font-size: 1.5em;
    font-weight: 600;
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 1em;
    }

    span:first-child {
      color: $grey-font-color;
      text-transform: uppercase;

      body.binance & {
        color: $b-secondatry-font-color;
      }
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.3em;

    .btn {
      margin-top: 1.5rem;
    }

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      margin-bottom: 1em;

      .btn {
        width: 100%;
      }
    }
  }
}

.accent {
  .accent-box {
    z-index: -1;
    padding: 5px;
    $size: -5px;
    position: absolute;
    top: $size;
    left: $size;
    right: $size;
    bottom: $size;
    background: transparent;
    overflow: hidden;
    filter: blur(8px);

    > div {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 160%;
      padding-top: 170%;
      transform: translate(-60%, -60%);
      background: linear-gradient(
          0,
          #ffacc5 0%,
          #ffc1b7 20%,
          #ffe6aa 40%,
          #a7eff6 60%,
          #ffe6aa 80%,
          #ffacc5 100%
      );

      body.binance & {
        background: linear-gradient(
            209.8deg,
            rgba(189, 0, 255, 0.6) 4.27%,
            rgba(255, 0, 0, 0.6) 34.52%,
            rgba(219, 0, 255, 0.6) 69.25%,
            rgba(248, 37, 113, 0.6) 97.53%
        );
      }

      background-size: 300% 300%;
      animation: rainbowRotate 10s linear infinite;
    }
  }
}

.product-card__uni {
  img {
    object-fit: contain;
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
}

@keyframes rainbowRotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
