<script>
import Button from '@/components/Button';
import { mapState } from 'vuex';
import RegisterButton from '@/components/RegisterButton';
import WithdrawButton from '@/components/WithdrawButton';

export default {
  name: 'ShadowButtonsKit',
  components: {
    Button,
    RegisterButton,
    WithdrawButton,
  },
  computed: {
    ...mapState({
      sign: (state) => state.User.sign,
      milkBalance: (state) => state.Milk.milkBalance,
    }),
    pending() {
      if (this.sign?.amount) return this.$contracts.fromWei(this.sign.amount);
      return 0;
    },
  },
};
</script>

<template>
  <v-row justify="center" class="shadow-buttons">
    <RegisterButton v-if="!sign || sign === 'notregistered'" />
    <template v-else>
      <v-col :sm="3">
        <span class="shadow-buttons__param">{{
          $t("shadow.your-balance")
        }}</span>
        <span class="shadow-buttons__value">
          {{ milkBalance !== null ? milkBalance : $t("loading") | amount(4) }}
        </span>
        <Button
          :href="
            $chain.getLinkByNetwork(
              `https://app.uniswap.org/#/swap?inputCurrency=0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de`,
              `https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5`
            )
          "
        >
          {{ $t("product.trade") }}
        </Button>
      </v-col>
      <v-col :sm="3">
        <span class="shadow-buttons__param">{{
          $t("shadow.pending-balance")
        }}</span>
        <span class="shadow-buttons__value">
          {{ pending ? pending : $t("loading") | amount(4) }}
        </span>
        <WithdrawButton />
      </v-col>
    </template>
  </v-row>
</template>

<style lang="scss">
.shadow-buttons {
  padding: 8rem 0;
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  @media screen and (max-width: $breakpoint-sm) {
    padding: 4rem 0;
  }
  &__param {
    display: block;
  }
  &__value {
    display: block;
    color: $main-pink;
    margin: 1em 0;

    body.binance & {
      color: $b-binance-color;
    }
  }
}
</style>
