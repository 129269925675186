<script>
import { mapState } from 'vuex';
import ProductCard from '@/components/ProductCard';
import ShadowButtonsKit from '@/components/ShadowButtonsKit';
import axios from 'axios';

const SHEET_IDS = {
  ethereum: '1ddOxV5CKkKRobMeJqOgmueUFDlthIYiCAZs2kbdRA-M',
  binance: '10vOpPHfI0CmtDr0BpsPDZNhNGXSLnOc45PbQ2rdEGdE',
  avalanche: '1oaJlTZfrGHR2938vYfC32v9GSAh2wkbBaA9ZVPrXXis',
};

export default {
  name: 'Index',
  components: {
    ShadowButtonsKit,
    ProductCard,
  },

  data: () => ({
    milk_per_block: {},
  }),

  computed: {
    ...mapState({
      authenticated: (state) => state.User.ethAddress,
      poolsInterstellar: (state) => (state.Shadow.pools && state.Shadow.pools.interstellar) || [],
      poolsGalaxy: (state) => state.Shadow.pools.galaxy,
      chainId: (state) => state.User.chainId,
    }),
  },

  methods: {
    async getMilk(net) {
      const url = `https://docs.google.com/spreadsheets/d/${SHEET_IDS[net]}/export?format=csv`;
      const { data } = await axios.get(url);
      return data;
    },
  },

  async created() {
    this.milk_per_block = {
      ethereum: await this.getMilk('ethereum'),
      binance: await this.getMilk('binance'),
      avalanche: await this.getMilk('avalanche'),
    };
  },
};
</script>

<template>
  <div class="main-wrap main-wrap--narrow">
    <div class="page-content">
      <h1 class="page-title">{{ $t('menu.earn-milky', { token: 'MILK2'}) }}</h1>
      <div class="milk-per-block"> MILK2 per block: {{ milk_per_block[$chain.getName()] }}</div>
      <ShadowButtonsKit />
      <v-row>
        <ProductCard
          v-for="(product, pid) in poolsInterstellar"
          :key="pid"
          :product="product"
        />
      </v-row>
    </div>
  </div>
</template>

<style lang="scss">
.milk-per-block{
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  display: none;
  body.binance & {
    display: block;
  }
  body.avalanche & {
    display: block;
  }
  body.ethereum & {
    display: block;
  }
}
</style>
